import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/landing';

import AllTheDevices from 'components/content-blocks/all-the-devices';
import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import FeaturesGrid from 'components/content-blocks/features-grid';
import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import MobileFeatures from 'components/content-blocks/mobile-features';
import IntegrationsInfo from 'components/globals/integrations-info';
import Integrations from 'components/globals/payroll-integrations';
import Yoast from 'components/globals/yoast';
import { HeroEnterprise } from 'components/hero/enterprise';

import './styles/healthcare.scss';

export default function Enterprise() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "health-care" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                copy
                schedule_demo {
                  button_text
                }
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                }
              }
              media_object {
                body
                sub_title
                title
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                button_url
                button_text
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }

              payroll_integrations {
                background_image_desktop {
                  source_url
                }
                background_image_mobile {
                  source_url
                }
                title
                sub_title
                copy
                logos {
                  logo {
                    source_url
                    alt_text
                  }
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }

              cards_1 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
              cards_2 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          integrations_info {
            title
            sub_title
            copy
            computer_image_desktop {
              source_url
            }
            computer_image_mobile {
              source_url
            }
            curve_image {
              source_url
            }
          }
          customer_reviews {
            title
            sub_title
            customer {
              name
              company
              quote
              image {
                source_url
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const mediaObject = pageContext.media_object;
  const integrationInfo = data.wordpressAcfOptions.options.integrations_info;
  const integrations = pageContext.payroll_integrations;
  const featuresGrid = pageContext.features_grid;
  const featuresGridMobile = pageContext.features_grid_mobile_1;
  const cards1 = pageContext.cards_1;
  const cards2 = pageContext.cards_2;
  const customerReviews = data.wordpressAcfOptions.options.customer_reviews;

  return (
    <Layout>
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <div className="lp-healthcare-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroEnterprise
          { ...hero }
          scheduleDemoClassName="hero button is-primary is-rounded"
          logosDesktop="https://marketing-assets.wheniwork-production.com/2020/09/02113804/Logos.svg"
          logosMobile="https://marketing-assets.wheniwork-production.com/2020/09/02113804/Logos.svg"
        />
        <HighlightCards
          { ...cards1 }
          className="highlight-basic"
          sub_title={ cards1.header.title }
          hasTitle
          hasCopy
          hasImage
        />
        <AllTheDevices />
        <Integrations { ...integrations } />
        <MediaObject { ...mediaObject[0] } />
        <IntegrationsInfo { ...integrationInfo } />
        <MobileFeatures { ...featuresGridMobile } />
        <FeaturesGrid { ...featuresGrid[0] } />
        <HighlightCards
          { ...cards2 }
          sub_title={ cards2.header.title }
          hasTitle
          hasCopy
          className="highlight-stats"
        />
        <CustomerReviews { ...customerReviews } hasImage hasAwards />
        <MediaObject { ...mediaObject[1] } className="flexible" />
      </div>
    </Layout>
  );
}
