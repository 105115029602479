import Signup from 'components/modal/signups';

import './styles/all-the-devices.scss';

type AllTheDevicesProps = {
  copy?: any;
  signup?: {
    modal_launch_button_text?: string;
    modal_title?: string;
    title?: any;
  };
  title?: string;
};

const AllTheDevices = ({
  signup,
  title,
  copy,
}:AllTheDevicesProps) => {
  return (
    <section
      className={ `all-the-devices-section section ${
        Boolean(signup || title || copy) ? 'has-addons' : ''
      }` }
    >
      <div className="container">
        {title && <h2 className="headline">{title}</h2>}
        {copy && (
          <p className="copy" dangerouslySetInnerHTML={ { __html: copy } } />
        )}
        {signup && (
          <section className="signup-section">
            <h3
              className="signup-title"
              dangerouslySetInnerHTML={ { __html: signup.title } }
            />
            <Signup
              uniqueId="allTheDevices"
              modalTitle={ signup.modal_title }
              showInitFields
              buttonInline
              fieldLayout="row"
              signupModalLaunch={ signup.modal_launch_button_text }
            />
          </section>
        )}
        <img
          src="https://marketing-assets.wheniwork-production.com/2021/08/19143331/all-the-devices-1.svg"
          alt="Time Clock punch in on any device"
        />
      </div>
    </section>
  );
};

export default AllTheDevices;
